@import './src/scss/variables';
@import './src/scss/app';

.widget__submenu-list a span {
	display: flex;
  align-items: center;
  gap: .5rem;
  text-decoration: none;
}

.widget__result {
	display: initial;
}